<template>
  <div class="service-container" :class="displayCenter ? 'display-center' : ''">
    <div v-for="item in dataList" :key="item.id" :class="displayCenter ? 'item-div' : ''">
      <div class="introduce-img">
        <img :src=item.img alt="" />
      </div>
      <div class="introduce-text-div">
        <div class="margin-div">
          <div class="introduce-text-name">{{item.name}}</div>
          <div class="introduce-text-info"><span>{{item.info}}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['dataList', 'displayCenter'],
  name: 'ShowServiceData'
}
</script>
<style scoped>
.service-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  width: 100%;
}

.display-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .service-container {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .service-container {
    margin-bottom: 10px;
  }
}

.item-div {
  margin: 0 5% 0 5%;
}

@media (max-width: 768px) {
  .item-div {
    margin: 0 5px 10px 5px
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .item-div {
    margin: 0 0 40px 30px;
  }
}

.introduce-text-div{
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 361px;
  height: 307px;
  background: #E60012;
}

@media (max-width: 768px) {
  .introduce-text-div{
    padding: 10px;
    width: 92px;
    height: 136px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 976px) {
  .introduce-text-div{
    padding: 24px;
    width: 240px;
    height: 210px;
  }
}

@media only screen and (min-width: 976px) and (max-width: 1600px) {
  .introduce-text-div{
    padding: 24px;
    width: 240px;
    height: 290px;
  }
}

.introduce-img{
  width: 360px;
  height: 560px;
}

.introduce-img img{
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .introduce-img{
    width: 92px;
    height: 155px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .introduce-img{
    width: 240px;
    height: 380px;
  }
}

@media only screen and (min-width: 976px) and (max-width: 1600px) {
  .introduce-img{
    width: 240px;
    height: 420px;
  }
}

.margin-div{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
}

.introduce-text-name{
  height: 29px;
  font-weight: 600;
  font-size: 2rem;
  color: #FFFFFF;
  line-height: 46px;
}
@media (max-width: 768px) {
 .introduce-text-name{
   padding: 0;
   font-size: 0.8rem;
   line-height: 1rem;
 }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .introduce-text-name{
    padding: 0;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

.introduce-text-info{
  font-weight: 400;
  font-size: 1.25rem;
  color: #FFFFFF;
  line-height: 35px;
}
@media (max-width: 768px) {
  .introduce-text-info{
    font-size: 0.625rem;
    line-height: 0.8rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .introduce-text-info{
    width: 100%;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
