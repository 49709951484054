<template>
  <div class="app-container" id="漫调服务">
    <div class="home-bg bg-image"></div>
    <div class="service-info-div" id="服务特色">
      <div class="container-div">
        <div class="content-div">
          <div class="service-special-div">
            <div class="service-special">
              <border-box title="SERVICE" text="服务特色" title-color="red"></border-box>
            </div>
            <div class="service-description-div">
              <div class="margin-div">
                <div class="service-text">{{title}}</div>
                <div class="service-description">{{description}}</div>
              </div>
            </div>
          </div>
          <div class="service-logo">
            <img src="@/assets/images/service/logo.png" alt="">
          </div>
        </div>
        <show-service-data :data-list="dataList.filter(item=>item.id <=3)"></show-service-data>
        <show-service-data :data-list="dataList.filter(item=>item.id >3)" :display-center="true"></show-service-data>
      </div>
    </div>
    <div style="width: 100%;height: 100%" id="业务范围"><introduce-business show-text-info></introduce-business></div>
    <div class="commitment-div" id="服务承诺">
      <div class="commit-container"><border-box title="COMMIT" text="服务承诺" title-color="red"></border-box></div>
      <commit-data :commit-list="commitList.filter(item=> item.id<=3)"></commit-data>
      <commit-data :commit-list="commitList.filter(item=> item.id > 3 )"></commit-data>
    </div>
    <div class="case-div" id="服务案例">
      <div class="case-border">
        <border-box title="CASE" text="服务案例" background-color="normal" title-color="red"></border-box>
      </div>
      <div class="case-service-img">
        <img src="@/assets/images/service/case.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { handleScrollToAnchor } from '@/util/tool'
import IntroduceBusiness from '@/components/content/components/introduce-business.vue'
import BorderBox from '@/components/common/border-box.vue'
import CommitData from '@/components/content/components/commit-data.vue'
import ShowServiceData from '@/components/content/components/show-service-data.vue'
export default {
  components: { BorderBox, ShowServiceData, IntroduceBusiness, CommitData },
  name: 'service-info',
  props: {
    jumpName: {
      type: String
    }
  },
  data () {
    return {
      dataList: [
        { id: 1, name: '财税服务一体化', info: '提供全面财税服务，实现管理、筹划与处理的高效协同。', img: 'https://imandiao.oss-cn-beijing.aliyuncs.com/website/images/service/case1.png' },
        { id: 2, name: '事务办理一站式', info: '工商注册、变更、注销等流程一站式办理，专业快捷。', img: 'https://imandiao.oss-cn-beijing.aliyuncs.com/website/images/service/case4.png' },
        { id: 3, name: '知识产权全种类', info: '涵盖各类知识产权服务，全方位保护企业创新成果。', img: 'https://imandiao.oss-cn-beijing.aliyuncs.com/website/images/service/case2.png' },
        { id: 4, name: '资质办理全覆盖', info: '专业人员定制方案、准备材料、全程陪审，确保证书顺利获批。', img: 'https://imandiao.oss-cn-beijing.aliyuncs.com/website/images/service/case5.png' },
        { id: 5, name: '政策申报全流程', info: '深入研究政策，提供全流程申报指导，助力客户享受政策优惠。', img: 'https://imandiao.oss-cn-beijing.aliyuncs.com/website/images/service/case3.png' }
      ],
      commitList: [
        { id: 1, name: '进度不担忧', description: 'PROGRESS', icon: require('@/assets/images/service/commitment1.png') },
        { id: 2, name: '记账不超期', description: 'ACCOUNTING', icon: require('@/assets/images/service/commitment3.png') },
        { id: 3, name: '政策不错过', description: 'POLICY', icon: require('@/assets/images/service/commitment4.png') },
        { id: 4, name: '材料不缺失', description: 'MATERIAL', icon: require('@/assets/images/service/commitment5.png') },
        { id: 5, name: '申报不错过', description: 'DECLARE', icon: require('@/assets/images/service/commitment2.png') },
        { id: 6, name: '咨询不拖延', description: 'INFORMATION', icon: require('@/assets/images/service/commitment6.png') }
      ],
      title: '漫调企服 量身定制',
      description: '我们让创业化繁为简  为客户提供最需要的专业服务  致力于协助客户安全、高效的运营企业。'
    }
  },
  watch: {
    jumpName: {
      handler (val) {
        if (this.hasMounted && val) this.handleScrollToAnchor(val)
      },
      immediate: true
    }
  },
  mounted () {
    this.hasMounted = true
    if (this.jumpName) {
      this.handleScrollToAnchor(this.jumpName)
    }
  },
  methods: {
    handleScrollToAnchor
  }
}
</script>
<style scoped>
@import "@/assets/styles/common.css";
.bg-image{
  background: url('https://imandiao.oss-cn-beijing.aliyuncs.com/website/images/service/banner.png') center no-repeat;
  background-size: cover;
  object-fit: cover;
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .home-bg{
    height: 480px;
  }
}

.container-div{
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

@media (max-width: 768px) {
  .container-div{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }
}

@media only screen and (min-width: 410px) and (max-width: 768px) {
  .container-div{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .container-div{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }
}

@media only screen and (min-width: 976px) and (max-width: 1400px) {
  .container-div{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }
}

.service-info-div{
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-div{
  margin: 150px 0 76px 0 ;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .content-div{
    margin: 40px 0 25px 0 ;
  }
}

.service-logo{
  min-width: 60px;
}

.service-logo img{
  width: 100%;
  height: auto;
}

.service-special-div{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin: 0 61px 0 0;
}

@media (max-width: 768px) {
  .service-special-div{
    margin: 0;
  }
}

.service-description-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 760px;
  height: 304px;
  background: #E60012;
  border-radius: 0 0 47px 0;
}

@media (max-width: 768px) {
  .service-description-div{
    width: 240px;
    height: 101px;
    border-radius: 0 0 16px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .service-description-div{
    width: 520px;
    height: 240px;
    border-radius: 0 0 36px 0;
  }
}

.margin-div{
  width: 90%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.service-special{
  margin: 0 0 51px 0;
}

@media (max-width: 768px) {
  .service-special{
    margin: 0 0 30px 0;
  }
}

.service-text{
  font-weight: 600;
  font-size: 2rem;
  color: #FFFFFF;
  line-height: 40px;
}

@media (max-width: 768px) {
  .service-text{
    font-size: 1rem;
    line-height: 20px;
  }
}

.service-description{
  font-weight: 400;
  font-size: 1.4rem;
  color: #FFFFFF;
  line-height: 39px;
}

@media (max-width: 768px) {
  .service-description{
    font-size: 0.625rem;
    line-height: 1rem;
  }
}

.commitment-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding-bottom: 126px;
}

@media (max-width: 768px) {
  .commitment-div{
    padding-bottom: 21px;
  }
}

.commit-container{
  margin: 20px 0 0 0 ;
}

.case-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.case-border{
  margin-top: 135px;
}

@media (max-width: 768px) {
  .case-border{
    margin-top: 40px;
  }
}

.case-service-img{
  margin-top: 54px;
}

.case-service-img img{
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .case-service-img {
    margin-top: 27px;
  }
}
</style>
