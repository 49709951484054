<template>
  <div class="big-box">
    <div class="box-border">
      <div class="box-title">ENTERPRISE</div>
      <div class="box-text">入驻企业展示</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'enterprise'
}
</script>

<style scoped lang="less">
.big-box{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 440px;
  height: 90px;
}

@media (max-width: 768px) {
  .big-box{
    width: 200px;
    height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .space-img{
    width: 320px;
    height: auto;
  }
}

.box-border{
  position: relative;
  width: 100%;
  height: 60px;
  border: 2px solid #646262;
}

@media (max-width: 768px) {
  .box-border{
    height: 30px;
  }
}
.box-title{
  position: absolute;
  text-align: center;
  font-weight: 600;
  background-color: #ffffff;
  color: rgba(230, 0, 18, 1);
  font-size: 3.125rem;
  height: 50px;
  bottom: 75%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .box-title{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.375rem;
    height: 16px;
    bottom: 75%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-title{
    bottom: 65%;
  }
}

.box-text{
  position: absolute;
  text-align: center;
  font-weight: 500;
  background-color: #FFFFFF;
  color: #646262;
  font-size: 1.875rem;
  width: 180px;
  height: 26px;
  left: 50%;
  top: 85%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .box-text{
    font-size: 1rem;
    width: 110px;
    height: 1rem;
    top: 90%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-text{
  }
}
</style>
