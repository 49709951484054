<template>
  <div class="big-box">
    <div class="box-border">
      <div class="box-title"><div>COMMUNITY</div></div>
      <div class="box-text">园区风采</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'border-box-case'
}
</script>

<style scoped lang="less">
.big-box{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 440px;
  height: 90px;
}
@media (max-width: 768px) {
  .big-box{
    width: 200px;
    height: 60px;
  }
}

.box-border{
  position: relative;
  width: 100%;
  height: 60px;
  border: 2px solid #FFFFFF;
}
@media (max-width: 768px) {
  .box-border{
    height: 30px;
  }
}

.box-title{
  position: absolute;
  text-align: center;
  font-weight: 600;
  background-color: rgba(230, 0, 18, 1);
  color: #fff;
  font-size: 3.125rem;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .box-title{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.375rem;
    height: 16px;
    bottom: 75%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-title{
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.box-text{
  position: absolute;
  text-align: center;
  font-weight: 500;
  background-color: rgba(230, 0, 18, 1);
  color: #FFFFFF;
  font-size: 1.875rem;
  height: 26px;
  left: 49%;
  top: 82%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .box-text{
    font-size: 1rem;
    height: 1rem;
    top: 90%;
  }
}
</style>
