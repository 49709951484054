<template>
  <div class="big-box">
    <div class="box-border">
      <div class="box-title" :class="titleClass"><div>{{title}}</div></div>
      <div class="box-text" :class="textClass">{{text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'border-box',
  props: {
    bgColor: {
      gray: 'bg-gray',
      white: 'bg-white'
    },
    title: {
      type: String,
      default: 'BUSINESS'
    },
    text: {
      type: String,
      default: '业务范围'
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    titleColor: {
      type: String,
      default: 'normal'
    },
    textColor: {
      type: String,
      default: 'normal'
    }
  },
  data () {
    return {
      backgroundMap: {
        normal: 'bg-gray',
        white: 'bg-white'
      },
      colorMap: {
        normal: 'text-gray',
        white: 'text-white',
        red: 'text-red'
      }
    }
  },
  computed: {
    titleClass () {
      return {
        [this.colorMap[this.titleColor]]: true,
        [this.backgroundMap[this.backgroundColor]]: true,
        'box-title-business': this.title === 'BUSINESS',
        'box-title-long': this.title.length === 11,
        'box-title-medium': this.title.length === 10,
        'box-title-nine': this.title.length === 9,
        'box-title-eight': this.title.length === 8 && this.title !== 'BUSINESS',
        'box-title-short': this.title.length === 7,
        'box-title-six': this.title.length === 6,
        'box-title-four': this.title.length === 4,
        'box-title-twelve': this.title.length === 12
      }
    },
    textClass () {
      return {
        [this.colorMap[this.textColor]]: true,
        [this.backgroundMap[this.backgroundColor]]: true
      }
    }
  }
}
</script>

<style scoped lang="less">
.big-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 440px;
  height: 100px;
}

@media (max-width: 768px) {
  .big-box {
    width: 220px;
    height: 60px;
  }
}

.bg-gray {
  background: #f5f5f5;
}

.bg-white {
  background: #fff;
}

.box-border {
  position: relative;
  width: 100%;
  height: 60px;
  border: 2px solid #646262;
}

@media (max-width: 768px) {
  .box-border {
    height: 30px;
  }
}

.box-title {
  text-align: center;
  width: 80%;
  position: absolute;
  font-weight: 600;
  font-size: 3.125rem;
  height: 50px;
  bottom: 75%;
}

@media (max-width: 768px) {
  .box-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.375rem;
    height: 25px;
    bottom: 58%;
  }
}

.box-title-long {
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .box-title-long {
    left: 50%;
    transform: translateX(-50%);
  }
}

.box-title-medium {
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .box-title-medium {
    width: 68%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-title-medium  {
    left: 50%;
    transform: translateX(-50%);
    bottom: 65%;
  }
}

.box-title-short {
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .box-title-short {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-title-short {
    left: 50%;
    transform: translateX(-50%);
    bottom: 65%;
  }
}

.box-title-six {
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .box-title-six {
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.box-title-business {
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .box-title-business {
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-title-business {
    left: 50%;
    transform: translateX(-50%);
    bottom: 65%;
  }
}

.box-title-eight {
  width: 70%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .box-title-eight {
    width: 60%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-title-eight {
    left: 50%;
    transform: translateX(-50%);
    bottom: 65%;
  }
}

.box-title-nine {
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .box-title-nine {
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-title-nine {
    left: 50%;
    transform: translateX(-50%);
    bottom: 65%;
  }
}

.box-title-four {
  width: 30%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .box-title-four {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-title-four {
    left: 50%;
    transform: translateX(-50%);
    bottom: 65%;
  }
}

.box-title-twelve{
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 73%;
}

@media (max-width: 768px) {
  .box-title-twelve {
    width: 80%;
    bottom: 55%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-title-twelve {
    width: 85%;
    bottom: 65%;
  }
}

.box-text {
  position: absolute;
  text-align: center;
  font-weight: 500;
  color: #646262;
  font-size: 1.875rem;
  left: 50%;
  top: 96%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .box-text {
    font-size: 1rem;
    top: 95%;
    transform: translate(-50%, -40%);
  }
}
</style>
