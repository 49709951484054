import axios from 'axios'

const service = axios.create({
  // baseURL: 'http://192.168.9.237:8000/v1/', // url = base url + request url
  baseURL: 'https://imdapi.xinmandiao.com/v1/',
  timeout: 5000 // request timeout
})

service.interceptors.request.use(
  config => {
    config.data = JSON.stringify(config.data)
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.status !== 10000) {
      console.log('接口信息报错', res) // 打印整个响应数据
      const errorMessage = res.message || '服务器返回错误'
      return Promise.reject(new Error(errorMessage))
    } else {
      return res
    }
  },
  error => {
    let errorMessage = '接口信息报错'
    if (error.response) {
      errorMessage += ` [${error.response.status}] ${error.response.statusText}`
    } else if (error.request) {
      errorMessage += ' 请求超时或服务器无响应'
    } else {
      errorMessage += ` ${error.message}`
    }
    console.log(errorMessage)
    return Promise.reject(error)
  }
)

export default service
