<template>
  <div class="images-div">
    <div class="images-div-gap">
      <div>
        <img class="space-img" :src="`${imageUrlDomain}images/case/case1.png`" alt="">
      </div>
      <div style="margin-bottom: 7px">
        <img class="space-chair-img" :src="`${imageUrlDomain}images/case/case2.png`" alt="">
        <img class="space-img-last" :src="`${imageUrlDomain}images/case/case3.png`" alt="">
      </div>
    </div>
    <div class="images-div-gap">
      <div>
        <img class="image-case" :src="`${imageUrlDomain}images/case/case7.png`" alt="">
      </div>
      <div>
        <img class="image-case" :src="`${imageUrlDomain}images/case/case5.png`" alt="">
      </div>
      <div>
        <img class="image-case image-case-last" :src="`${imageUrlDomain}images/case/case4.png`" alt="">
      </div>
    </div>
    <div class="images-div-gap">
      <div>
        <img class="show-image" :src="`${imageUrlDomain}images/1735282092873.jpg`" alt="">
      </div>
      <div>
        <img class="last-show-image" :src="`${imageUrlDomain}images/case/case6.png`" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import { imageUrlDomain } from '@/common-data/image-domain'

export default {
  name: 'case-images',
  data () {
    return {
      imageUrlDomain
    }
  }
}
</script>
<style scoped lang="less">

.img-border{
  border: 1px solid red;
}

.images-div {
  width: 100%;
  height: 100%;
}

.images-div-gap{
  margin-left: 17px;
}

@media (max-width: 768px) {
  .images-div-gap{
    margin-left: 8px;
  }
}

.space-img{
  width: 590px;
  height: 353px;
}

@media (max-width: 768px) {
  .space-img{
    width: 140px;
    height: 88px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .space-img{
    width: 320px;
    height: auto;
  }
}

.space-chair-img{
  width: 286px;
  height: 301px;
  margin: 17px 17px 0 0;
}

@media (max-width: 768px) {
  .space-chair-img{
    width: 66px;
    height: 75px;
    margin: 8px 8px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .space-chair-img{
    width: 151px;
    height: auto;
    margin: 17px 17px 0 0;
  }
}

.space-img-last{
  width: 286px;
  height: 301px;
}

@media (max-width: 768px) {
  .space-img-last{
    width: 66px;
    height: 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .space-img-last{
    width: 151px;
    height: auto;
  }
}

.image-case{
  width: 286px;
  height: 210px;
  margin-bottom: 17px;
}

@media (max-width: 768px) {
 .image-case{
   width: 68px;
   height: 50px;
   margin-bottom: 8px;
 }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .image-case{
    width: 154px;
    height: auto;
    margin-bottom: 12px;
  }
}

.image-case-last{
  margin-bottom: 8px
}

.show-image{
  width: 286px;
  height: 210px;
  margin-bottom: 17px;
}

@media (max-width: 768px) {
  .show-image{
    width: 71px;
    height: 52px;
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .show-image{
    width: 155px;
    height: auto;
    margin-bottom: 15px;
  }
}

.last-show-image{
  width: 286px;
  height: 439px;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .last-show-image{
    width: 71px;
    height: 110px;
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .last-show-image{
    width: 155px;
    height: auto;
    margin-bottom: 12px;
  }
}

</style>
