<script>
import ExperienceData from '@/components/content/components/experience-data.vue'
export default {
  name: 'PhoneExperienceData',
  extends: ExperienceData
}
</script>

<style scoped>
.container-content{
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.img-container{
  position: relative;
  width: 8.875rem;
  height: 8.6rem;
}

.img-container img{
  width: 100%;
  height: 100%;
}

.img-line{
  position: absolute;
  width: 0.1rem;
  height: 8.6rem;
  background: #595757;
  top: 99%;
  left: 51%;
}

.top-line{
  position: absolute;
  width: 0.1rem;
  height: 8.6rem;
  background: #595757;
  bottom: 100%;
  left: 22%;
}

.cycle{
  position: absolute;
  width: 1rem;
  height: 1rem;
  background: #E60012;
  border-radius: 50%;
  z-index: 9;
}

.left-cycle{
  top: 44%;
  right: 98%;
}

.bottom-cycle{
  top: 92%;
  left: 47%;
}

.right-cycle{
  top: 44%;
  left: 98%;
}

.top-cycle{
  left: 17%;
  bottom: 92%;
}

.text-container{
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 15rem;
  height: 8.6rem;
}

.red-bg{
  background: #E60012;
}

.gray-bg{
  background: #DCDDDD;
}

.year-text{
  width: 100%;
  text-align: right;
  font-weight: 400;
  font-size: 1rem;
  color: #FFFFFF;
  margin: 0 5px 0 0;
}

.another-year{
  width: 100%;
  text-align: right;
  font-weight: 400;
  font-size: 1rem;
}

.another-year-item{
  margin: 0 10px 5px 0 ;
}
.year{
  margin-right: 18px;
}

.year-item{
  margin: 10px 10px 0 0 ;
}

.text-white{
  color: #FFFFFF;
}

.text-gray{
  color: #595757;
}

.other-text{
  font-family: 'Microsoft YaHei', '微软雅黑', SimSun, '宋体', 'Lucida Grande', Tahoma, Arial, Helvetica, sans-serif;;
  margin: 0 0 0.5rem 1rem;
  font-weight: 400;
  line-height: 1rem;
  font-size: 0.6rem;;
}

.another-text{
  margin: 0.5rem 0 0 1rem;
  color: #595757;
}

.left-line{
  position: absolute;
  width: 8.6rem;
  height: 3px;
  background: #595757;
  right: 100%;
}

.right-line{
  position: absolute;
  width: 8.6rem;
  height: 3px;
  background: #595757;
  left: 100%;
}

.ul-style{
  padding: 0 0.3rem;
  margin: 0;
}
</style>
