<template>
  <div class="container">
    <div class="images-container">
      <div class="image-grid-left">
        <div v-for="(img,index) in item.images" :key="index+img" :class="index === 2 ? 'grid-item' : 'image-div'" >
          <img :src=img alt="" />
        </div>
      </div>
      <div class="department-left">
        <div class="department-img">
          <img :src=item.img alt="" />
        </div>
        <div class="description-div">
          <div class="text-content">
            <div class="name-div">
              <div>
                <div class="text-name">{{item.name}}</div>
                <div class="english-text">{{item.letters}}</div>
              </div>
              <div class="rdquo"></div>
            </div>
            <div class="description">
              <div>{{item.info}}</div>
              <div>地址：{{item.location}}</div>
              <div>聚焦产业：{{item.product}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'department-left',
  props: ['item']
}
</script>

<style scoped>
@import "@/assets/styles/park1.css";
</style>
