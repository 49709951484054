<template>
  <div class="app-container" id="爱漫调APP">
    <div class="home-bg bg-image"></div>
    <div class="work-life-boxing">
      <div class="work-life" id="APP简介">
        <div class="english-text">MANDIAO&nbsp;E&nbsp;SPACE</div>
        <div class="life-text">爱漫调APP简介</div>
      </div>
      <div class="info-text-box">
       <div class="info-text">爱漫调APP是为解决孵化载体、入驻企业、行业主管部门不同需求而推出的智能孵化管理服务平台。
         有效的解决了运营机构在营销推广、服务扭转、财务结算、智慧办公、服务可视化等问题，让招商更容易、管理更智能、服务更高效，
         也解决了入驻企业在交互、信用沉淀、业务开展、政策申报、金融等方面的现实问题，同时让行业主管部门能够实时掌握辖区中小企业各种信息，
         推出更加有效的促进创新创业高质量发展的方法和措施。
       </div>
      </div>
      <div class="app-download">
        <div class="download-img-div">
          <div class="img-border">
            <img src="@/assets/images/download-img.png" alt=""/>
          </div>
        </div>
        <div class="download-text">扫码下载</div>
        <div class="english-name">AIMANDIAO</div>
      </div>
    </div>
    <div class="business-boxing" id="主要功能">
      <div class="business-border">
        <main-function></main-function>
      </div>
      <div class="images-div">
        <div><img src="../../assets/images/app/app-function.png" alt=""/></div>
      </div>
      <show-data :data-list="dataList"></show-data>
    </div>
  </div>
</template>

<script>
import { handleScrollToAnchor } from '@/util/tool'
import MainFunction from '@/components/common/main-function.vue'
import ShowData from '@/components/content/components/show-data.vue'
export default {
  components: { MainFunction, ShowData },
  name: 'app-info',
  props: {
    jumpName: {
      type: String
    }
  },
  watch: {
    jumpName: {
      handler (val) {
        if (this.hasMounted && val) this.handleScrollToAnchor(val)
      },
      immediate: true
    }
  },
  mounted () {
    this.hasMounted = true
    if (this.jumpName) {
      this.handleScrollToAnchor(this.jumpName)
    }
  },
  data () {
    return {
      hasMounted: false,
      handleScrollToAnchor,
      dataList: [
        { id: 1, name: '数据中心', info: 'Data Center', children: ['数据采集', '大数据挖掘与分析', '政策金融信息匹配', '推送数据展示', '......'] },
        { id: 2, name: '智慧物联', info: 'Smart Internet', children: ['云门禁', '云打印', '智慧会议', '智慧安防', '智慧能源', '......'] },
        { id: 3, name: '数字管理', info: 'Digital Management', children: ['企业数据服务', '空间可视化管理', '服务可视化管理', '线索管理', '合同管理', '......'] },
        { id: 4, name: '数字营销', info: 'Digital Marketing', children: ['数字商城', '数字交易', '数字币券', '......'] },
        { id: 5, name: '数字社区', info: 'Digital Commitment', children: ['商学院活动', '美团外卖', '百度文心一言', '......'] }
      ]
    }
  }
}
</script>
<style scoped>
@import "@/assets/styles/common.css";
.bg-image{
  background: url('https://imandiao.oss-cn-beijing.aliyuncs.com/website/images/app/app-bg.png') center no-repeat;
  background-size: cover;
  object-fit: cover;
}

.work-life-boxing{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f5f5 ;
}

.work-life{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .work-life{
    height: 80px;
  }
}

.english-text{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 118px;
  font-size: 5rem;
  width: 100%;
  color: #fff;
  font-weight: 400;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .english-text{
    font-size: 2rem;
    height: 118px;
  }
}

.life-text{
  position: absolute;
  color:  #606060;;
  font-size: 2rem;
  letter-spacing: 1rem;
  top: 38%;
}

@media (max-width: 768px) {
  .life-text{
    letter-spacing: 8px;
    font-size: 1rem;
    top: 38%;
  }
}

.info-text-box{
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-text{
  text-indent: 2em;
  width: 60%;
  font-weight: 300;
  font-size: 1.4rem;
  color: #606060;
  line-height: 2.5rem;
}

@media (max-width: 768px) {
  .info-text{
    font-size: 0.75rem;
    line-height: 1.25rem;
    width: 90%;
  }
}
.app-download{
  margin: 86px 0 73px 0;
}

@media (max-width: 768px) {
  .app-download{
    margin: 22px 0 20px 0;
  }
}

.app-download img{
  width: 100%;
}

.download-img-div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-border{
  width: 200px;
  height: 205px;
  border: 1px dashed black;
}
@media (max-width: 768px) {
  .img-border{
    width: 142px;
    height: 140px;
  }
}

.img-border img{
  width: 100%;
  height: 100%;
}

.download-text{
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
  color: #606060;
  line-height: 4rem;
}

@media (max-width: 768px) {
  .download-text{
    font-size: 1rem;
  }
}

.english-name{
  text-align: center;
  font-weight: 300;
  font-size: 1.125rem;
  color: #606060;
  letter-spacing: 5px;
}

@media (max-width: 768px) {
  .english-name{
    font-size: 0.8rem;
    letter-spacing: 2px;
  }
}

.business-boxing{
  background:rgba(256, 256, 256, 1);
}
.business-border{
  padding-top: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .business-border{
    padding-top: 15px;
  }
}

.images-div{
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .images-div{
    margin-top: 30px;
  }
}

.images-div img{
  width: 100%;
  height: auto;
}
</style>
