<template>
  <div class="ai-btn" @click="linkToAI">
    <div class="ai-content">
      <img src="@/assets/images/kf-robot.png" alt="">
      <span style="font-size: 0.7rem">智能客服</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Artificial-intelligence',
  methods: {
    linkToAI () {
      window.open('http://aih5.xinmandiao.com/#/', '_blank')
    }
  }
}
</script>

<style scoped>
.ai-btn {
  position: fixed;
  right: 2rem;
  top: 50%;
  width: 80px;
  height: 80px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #F88A33;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}

.ai-btn::before,
.ai-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 2px solid #F88A33;
  border-radius: 50%;
  animation: ripple 2.5s linear infinite;
  z-index: -1;
  pointer-events: none;
}

@media (max-width: 768px) {
  .ai-btn {
    width: 48px;
    height: 48px;
    right: 1rem;
  }
}

.ai-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.ai-content img {
  width: 36px;
  height: 36px;
}

.ai-content span {
  color: #FFFFFF;
  font-size: 14px;
}

@media (max-width: 768px) {
  .ai-content img {
    width: 24px;
    height: 24px;
  }

  .ai-content span {
    color: #FFFFFF;
    font-size: 8px;
  }
}

@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0;
  }
}
</style>
