<template>
  <div class="business-boxing">
    <div class="business-border">
      <border-box border-color="normal" title-color="red"></border-box>
    </div>
    <div class="images-div">
      <div class="images">
        <div v-for="(item, index) in serviceData" :key="index" class="div-gap">
          <div @mouseenter="showText(item)" @mouseleave="showText(item)" style="max-width: 233px ;">
            <img class="item-img" :src="item.img" alt=""/>
          </div>
          <div :class="item.show ? 'show-text-div' : 'text-div'" style="max-width: 233px ;">
            <div class="control-show">
              <div class="text-content">
                <ul style="margin: 0; padding: 0 1rem">
                  <li v-for="(i, index) in item.text" :key="index">{{i}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BorderBox from '@/components/common/border-box.vue'
import { imageUrlDomain } from '@/common-data/image-domain'
export default {
  name: 'introduce-business',
  components: { BorderBox },
  props: {
    showTextInfo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // serviceData: [
      //   { id: 1, img: require('@/assets/images/business/transaction.png'), text: '工商设立/变更/注销\n经营异常解除\n工商年报\n印章申请（补刻）银行开户/变更/注销\n银行开户场地检查\n公积金开户/注销/人员增减\n社保开户/注销/迁移/人员增减', show: false },
      //   { id: 2, img: require('@/assets/images/business/tax.png'), text: '税务报道，税务变更/注销，税务异常处理，个体定额，乱账清理，一般纳税人认定，企业纳税等级评定，发票开具，发票增版增量小规模代理记账一般纳税人代理记账、财税托管、税务筹划、财税咨询/诊断。', show: false },
      //   { id: 3, img: require('@/assets/images/business/property.png'), text: '商标注册/变更/续展/转让，商标“撤”三，作品著作权，软件著作权，外观设计/实用新型/发明专利申请，专利变更/转让/驳回/复审集成电路布图设计登记，专利检索检测报告。', show: false },
      //   { id: 4, img: require('@/assets/images/business/policy.png'), text: '科技企业入库，创新型中小企业入库高新技术企业申请重庆专精特新中小企业申请，见习基地申报 ，科技计划项目立项，专精特新“小巨人”工业和信息化专项，创新型企业，商务发展专项资金，首台套与首版次征集项目绿色工厂/产品申报软件企业与软件产品双认证(双软认证)', show: false },
      //   { id: 5, img: require('@/assets/images/business/agency.png'), text: '食品经营/备案许可证，药品经营许可证，卫生许可证医疗机构职业许可证网络文化许可证，进出口许可证劳务派遣经营许可证，建筑资质许可证，出版物许可证人力资源经营许可证，增值电信业务经营许可证，医疗器械经营许可证、体系认证。', show: false }
      // ]
      serviceData: [
        { id: 1, img: `${imageUrlDomain}images/business/transaction.png`, text: ['工商年报', '经营异常解除', '银行开户场地检查', '工商设立/变更/注销', '公积金开户/注销/人员增减', '社保开户/注销/迁移/人员增减', '印章申请（补刻）银行开户/变更/注销'], show: false },
        { id: 2, img: `${imageUrlDomain}images/business/tax.png`, text: ['个体定额', '乱账清理', '发票开具', '一般纳税人认定', '企业纳税等级评定', '一般纳税人代理记账', '财税托管/咨询/诊断', '发票增版增量小规模代理记账', '税务报道/筹划/变更/注销/异常处理'], show: false },
        { id: 3, img: `${imageUrlDomain}images/business/property.png`, text: ['商标“撤”三', '作品著作权', '软件著作权', '集成电路布图设计登记', '商标注册/变更/续展/转让', '外观设计/实用新型/发明专利申请', '专利变更/转让/驳回/复审/检索检测报告'], show: false },
        { id: 4, img: `${imageUrlDomain}images/business/policy.png`, text: ['科技企业入库', '见习基地申报', '科技计划项目立项', '重庆专精特新中小企业申请', '创新型企业，商务发展专项资金', '专精特新“小巨人”工业和信息化专项', '创新型中小企业入库高新技术企业申请', '首台套与首版次征集项目绿色工厂/产品申报软件企业与软件产品双认证(双软认证)'], show: false },
        { id: 5, img: `${imageUrlDomain}images/business/agency.png`, text: ['药品经营许可证', '建筑资质许可证', '食品经营/备案许可证', '增值电信业务经营许可证', '医疗器械经营许可证、体系认证', '进出口许可证劳务派遣经营许可证', '出版物许可证人力资源经营许可证', '卫生许可证医疗机构职业许可证网络文化许可证'], show: false }
      ]
    }
  },
  methods: {
    showText (item) {
      if (!this.showTextInfo) return
      item.show = !item.show
    }
  }
}
</script>
<style scoped>
.business-boxing{
  background:rgba(256, 256, 256, 1);
  padding: 0 0 40px 0;
}

@media (max-width: 768px) {
  .business-boxing{
    padding: 0 0 20px 0;
  }
}
.business-border{
  padding-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .business-border{
    padding-top: 15px;
  }
}

.images-div{
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .images-div{
    margin-top: 30px;
  }
}

.images {
  display: flex;
  justify-content: center;
  align-items: start;
}
.item-img{
  width: 100%;
  height: auto;
}

.div-gap{
  position: relative;
  width: 100%;
  margin: 0 0;
}

@media (max-width: 768px) {
  .div-gap{
    margin: 0 0;
  }
}

.text-div{
  width: 100%;
  height: 0;
  overflow: hidden;
  background: #E6E6E6;
  transition: height 0.3s ease;
}

.show-text-div{
  width: 100%;
  height: 480px;
  background: #E6E6E6;
  transition: height 0.3s ease;
}

@media (max-width: 768px) {
  .text-div {
    height: 0;
  }
  .show-text-div{
    height: 540px;
  }
}

.control-show{
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}

.text-content{
  font-family: 'Microsoft YaHei', '微软雅黑', SimSun, '宋体', 'Lucida Grande', Tahoma, Arial, Helvetica, sans-serif;
  margin-top: 20px;
  width: 85%;
  font-weight: normal;
  font-size: 0.875rem;
  color: #595757;
  line-height: 1.75rem;
}

@media (max-width: 768px) {
  .text-content{
    margin-top: 10px;
    width: 85%;
    font-weight: normal;
    font-size: 0.5rem;
    color: #595757;
    line-height: 0.8rem;
  }
}

</style>
