<template>
  <div class="home">
    <div style="position: fixed;width: 100%;" :class="!showMenu ? '' : 'height-full'">
      <div class="head-div">
        <div class="logo-div">
          <img class="logo-img" src="@/assets/images/logo.png" alt="">
        </div>
        <div style="display: flex; align-items: center;" @click="showMenuList">
          <div style="width: 26px; height: 26px; margin-right: 10px">
            <img v-if="!showMenu" style="width: 100%; height: 100%" src="@/assets/icon/menu.png" alt="">
            <img v-else style="width: 24px; height: 24px;" src="@/assets/icon/close.png" alt="">
          </div>
        </div>
      </div>
      <div v-if="showMenu" style="height: 100%; width: 100%; background: #FFFFFF; z-index: 9999;overflow-y: auto;">
        <div v-for="item in dataList" :key="item.id">
          <div
            style="width: 100% ;display: flex; justify-content: space-between; align-items: center; padding: 20px 0 10px 0; font-size: 1rem; margin-left: 10px">
            <a style="" @click="checkMenu(item)">{{ item.name }}</a>
            <div style="margin-right: 20px" v-if="item.children.length" @click="showChildrenMenu(item)">
              <div class="arrow-div" :class="item.showChildren ? 'route-div' : ''"><img
                style="width: 100%; height: 100%" src="@/assets/icon/arrow.png" alt=""></div>
            </div>
          </div>
          <div v-if="item.showChildren">
            <ul style="list-style-type: none;margin: 0.625rem 1.25rem ;padding: 0; text-align: left;">
              <li class="sub-title" v-for="(c,i) in item.children" :key="i" @click="checkMenu(c)">
                <a @click="checkMenu(c)">{{ c.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { menuData } from '@/common-data/menu-data'

export default {
  name: 'PhoneHeader',
  data () {
    return {
      dataList: menuData,
      activeMenu: '首页',
      showMenu: false
    }
  },
  methods: {
    checkMenu (item) {
      this.showMenu = false
      if (!item.id) {
        const e = this.dataList.find(i => i.name === item.parentName)
        e.showChildren = false
      }
      this.$emit('checkSuccess', item)
    },
    showMenuList () {
      this.showMenu = !this.showMenu
    },
    showChildrenMenu (item) {
      item.showChildren = !item.showChildren
    }
  }
}
</script>
<style scoped lang="less">
.home {
  width: 100%;
  position: absolute;
  z-index: 999;
}

.height-full {
  height: 100%;
}

.activated {
  color: #E60012;
}

.unactivated {
  color: #fff;
}

.head-div {
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  height: 273px;
  width: 100%;
}

@media (max-width: 768px) {
  .head-div {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .head-div {
    height: 80px;
  }
}

.logo-div {
  width: 180px;
  height: 65px;
  margin-right: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .logo-div {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .logo-div {
    width: 120px;
    height: 40px;
    margin-top: 8px;
    margin-right: 0;
    margin-left: 10px;
  }
}

.logo-img {
  width: 100%;
  height: auto;
}

.subtitle {
  font-family: 'PingFangSC-Regular', sans-serif;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin: 10px 20px;
}

@media (max-width: 768px) {
  .subtitle {
    font-weight: 800;
    font-size: 10px;
    margin: 10px 5px;
  }
}

.subtitle-text {
  font-family: 'PingFangSC-Regular', sans-serif;
  cursor: pointer;
  margin-top: 9px;
  text-align: center;
  font-weight: 300;
  font-size: 15px;
  color: #FFFFFF;
  line-height: 30px;
}

@media (max-width: 768px) {
  .subtitle-text {
    margin: 3px 0;
    font-size: 8px;
    cursor: pointer;
    line-height: 16px;
  }
}

.logo-text {
  text-align: center;
}

.logo-text-name {
  letter-spacing: 8px;
  font-weight: 900;
  font-size: 10px;
  margin-left: 10px;
  width: 90px;
}

.foot-text {
  letter-spacing: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 1.25rem;
}

.sub-title {
  margin: 0.625rem 0;
  font-family: 'PingFangSC-Light', sans-serif;
  font-size: 0.9rem;
}

.subMenuList {
  display: none;
  overflow: hidden;
}

.showMenu {
  background-color: blue;
  display: block;
  overflow: visible;
  transition: opacity .1s ease;
}

.arrow-div {
  width: 18px;
  height: 18px;
  transition: transform 0.5s ease;
}

.route-div {
  transform: rotate(90deg);
  transition: transform 0.5s ease;
}

/* 媒体查询，针对小屏幕（如手机）进行样式调整 */
</style>
