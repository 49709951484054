<template>
  <div class="commit-list">
    <div v-for="item in commitList" :key="item.id" class="commit-item">
      <div class="commitment-content-div">
        <img :src=item.icon alt=""/>
      </div>
      <div class="commitment-name">{{item.name}}</div>
      <div class="commitment-description">{{item.description}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CommitData',
  props: ['commitList']
}
</script>
<style scoped>
.commit-list{
  display: flex;
  justify-items: center;
  align-items: center;
}

.commit-item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 82px 84px 0 84px;
}

@media (max-width: 768px) {
  .commit-item{
    margin: 15px 20px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .commit-item{
    margin: 82px 60px 0 60px;
  }
}

.commitment-content-div{
  width: 100px;
  height: 100px;
}

.commitment-content-div img{
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .commitment-content-div{
    width: 50px;
    height: 50px;
  }
}

.commitment-name{
  font-weight: 400;
  font-size: 1rem;
  color: #595757;
  line-height: 3rem;
}

@media (max-width: 768px) {
  .commitment-name{
    font-size: 0.625rem;
    line-height: 1.25rem;
  }
}

.commitment-description{
  font-weight: 400;
  font-size: 0.875rem;
  color: #595757;
}

@media (max-width: 768px) {
  .commitment-description{
    font-size: 0.5rem;
  }
}
</style>
