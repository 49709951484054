<template>
  <div class="app-container" style="background-color: #FFFFFF">
    <div class="split-line"></div>
    <div class="div-flex">
      <div v-for="(item,index) in dataList" :key="index">
        <div class="subtitle">
          <div @click="checkFootMenu(item)">{{item.name}}</div>
          <div class="subtitle-text" :class="item.name !== '联系我们' ? 'text-center' : '' ">
            <div class="sub-title" v-for="(c,i) in item.children" :key="i" >
              <a @click="checkFootMenu(c)">{{c.name}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="img-div">
        <img class="download-img" :src="`${imageUrlDomain}images/download-img.png`" alt="">
      </div>
    </div>
    <div class="logo-div">
      <div><img class="logo-image" :src="`${imageUrlDomain}images/logo.png`" alt=""></div>
      <div class="bottom-line"></div>
    </div>
  </div>
</template>
<script>
import { menuData } from '@/common-data/menu-data'
import { imageUrlDomain } from '@/common-data/image-domain'

export default {
  name: 'FOOTER',
  data () {
    return {
      imageUrlDomain,
      dataList: [
        ...menuData,
        { id: 7, name: '联系我们', children: [{ id: 8, name: '地址：重庆市两江新区星光大道60号金星科技大厦C栋5楼' }, { id: 9, name: '电话：023-86228000' }, { id: 10, name: '邮箱：mandiao@imandiao.cn' }] }
      ]
    }
  },
  methods: {
    checkFootMenu (item) {
      if (item.id >= 7) return
      this.$emit('checkSuccess', item)
    }
  }
}
</script>

<style scoped>
.split-line{
  width: 100%;
  height: 6px;
  background-color: red;
}

.div-flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  width: 100%;
}

.subtitle{
  font-family: 'Microsoft YaHei',serif;
  cursor: pointer;
  font-size: 1rem;
  margin: 40px 20px;
}

.subtitle-text{
  font-family: 'Microsoft YaHei',serif;
  margin: 10px 0;
  cursor: pointer;
}
.logo-div{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.img-div {
  margin: 40px 0;
}
.download-img{
  width: 100px;
  height: 100px;
}
.logo-image{
  width: 144px;
  height: 50px;
}
.bottom-line{
  width: 47%;
  height: 2px;
  background-color: #999;
  position: relative;
}
.bottom-line::after{
  position: absolute;
  content: "渝公网安备 50011202503901";
  right: 0;
  top: 4px;
  font-size: 0.75rem;
  color: #999;
}
.sub-title{
  font-family: 'Microsoft YaHei',serif;
  margin-bottom: 8px;
  font-size: 0.875rem;
}

/* 媒体查询，针对小屏幕（如手机）进行样式调整 */
@media (max-width: 768px) {
  .div-flex {
    display: flex;
    align-items: start;
    justify-content: center;
  }
  .subtitle{
    font-size: 0.625rem;
    margin: 0.5rem 0.5rem 0 0.5rem;
  }
  .subtitle-text{
    margin: 10px 0;
    cursor: pointer;
  }
  .sub-title{
    font-size: 0.56rem;
    margin-bottom: 6px;
  }
  .img-div{
    margin-top: 8px;
  }
  .bottom-line{
    width: 50%;
    height: 2px;
    background-color: #999;
    position: relative;
  }
  .bottom-line::after{
    position: absolute;
    content: "渝公网安备 50011202503901";
    right: 0;
    top: 0.25rem;
    font-size: 0.625rem;
    color: #999;
  }
}
</style>
