<template>
  <div class="big-box">
    <div class="box-border">
      <div class="box-title">MANDIAO INFORMATION</div>
      <div class="box-text">漫调资讯</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InformationCenter'
}
</script>
<style scoped lang="less">
.big-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 750px;
  height: 140px;
}

@media (max-width: 768px) {
  .big-box {
    width: 280px;
    height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .space-img {
    width: 320px;
    height: auto;
  }
}

.box-border {
  position: relative;
  width: 100%;
  height: 80px;
  border: 2px solid #646262;
}

@media (max-width: 768px) {
  .box-border {
    height: 30px;
  }
}

.box-title {
  width: 90%;
  position: absolute;
  text-align: center;
  font-weight: 600;
  background-color: #ffffff;
  color: rgba(230, 0, 18, 1);
  font-size: 50px;
  bottom: 60%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .box-title {
    width: 85%;
    font-size: 1.125rem;
    left: 50%;
    bottom: 60%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-title{
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.box-text {
  position: absolute;
  text-align: center;
  font-weight: 500;
  background-color: #FFFFFF;
  color: #646262;
  font-size: 30px;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .box-text {
    font-size: 1rem;
    top: 90%;
    transform: translate(-50%, -35%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-text {
  }
}
</style>
