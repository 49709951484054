<template>
  <div class="container-content">
    <!--    <div class="top-line" v-if="item.topLine"></div>-->
    <div class="cycle top-cycle" v-if="item.topCycle"></div>
    <div class="left-line" v-if="item.leftLine"></div>
    <div class="cycle left-cycle" v-if="item.leftCycle"></div>
    <div class="img-container">
      <img :src=item.img alt="" />
      <div class="cycle bottom-cycle" v-if="item.bottomCycle"></div>
      <div class="img-line" v-if="item.bottomLine"></div>
    </div>
    <div v-if="item.yearTop" class="text-container" :class="item.background === 'red' ? 'red-bg' : 'gray-bg'">
      <div class="year-text">
        <div class="year-item" :class="item.background === 'red' ? 'text-white' : 'text-gray'">“{{item.year}}</div>
      </div>
      <div class="other-text">
        <div :class="item.background === 'red' ? 'text-white' : 'text-gray'">
          <ul class="ul-style">
            <li v-for="(t, index) in item.children" :key="index">{{t}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else class="text-container" :class="item.background === 'red' ? 'red-bg' : 'gray-bg'">
      <div class="other-text another-text">
        <div :class="item.background === 'red' ? 'text-white' : 'text-gray'">
          <ul class="ul-style">
            <li v-for="(t, index) in item.children" :key="index">{{t}}</li>
          </ul>
        </div>
      </div>
      <div class="another-year">
        <div class="another-year-item" :class="item.background === 'red' ? 'text-white' : 'text-gray'">“{{item.year}}</div>
      </div>
    </div>
    <div class="cycle right-cycle" v-if="item.rightCycle"></div>
    <div class="right-line" v-if="item.rightLine"></div>
  </div>
</template>

<script>
export default {
  name: 'experienceData',
  props: ['item']
}
</script>

<style scoped>
.container-content{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.img-container{
  position: relative;
  width: 296px;
  height: 200px;
}

.img-container img{
  width: 100%;
  height: 100%;
}

.img-line{
  position: absolute;
  width: 3px;
  height: 8.6rem;
  background: #595757;
  top: 99%;
  left: 51%;
}

.top-line{
  position: absolute;
  width: 3px;
  height: 8.6rem;
  background: #595757;
  bottom: 100%;
  left: 22%;
}

.cycle{
  position: absolute;
  width: 18px;
  height: 18px;
  background: #E60012;
  border-radius: 50%;
  z-index: 9;
}

.left-cycle{
  top: 46%;
  right: 98%;
}

.bottom-cycle{
  top: 92%;
  left: 47%;
}

.right-cycle{
  top: 46%;
  left: 98%;
}

.top-cycle{
  left: 20.5%;
  bottom: 92%;
}

.text-container{
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 400px;
  height: 200px;
}

.red-bg{
  background: #E60012;
}

.gray-bg{
  background: #DCDDDD;
}

.year-text{
  width: 100%;
  text-align: right;
  font-weight: 400;
  font-size: 31px;
  color: #FFFFFF;
  margin: 0 5px 0 0;
}

.another-year{
  width: 100%;
  text-align: right;
  font-weight: 400;
  font-size: clamp(12px, 4vw, 30px);;
}

.another-year-item{
  margin: 0 10px 5px 0 ;
}
.year{
  margin-right: 18px;
}

.year-item{
  margin: 10px 10px 0 0 ;
}

.text-white{
  color: #FFFFFF;
}

.text-gray{
  color: #595757;
}

.other-text{
  margin: 0 0 17px 16px;
  font-weight: 400;
  line-height: 1.4rem;
  font-size: 0.875rem;;
}

.another-text{
  margin: 19px 0 0 16px;
  color: #595757;
}

.left-line{
  position: absolute;
  width: 8.6rem;
  height: 3px;
  background: #595757;
  right: 100%;
}

.right-line{
  position: absolute;
  width: 8.6rem;
  height: 3px;
  background: #595757;
  left: 100%;
}

.ul-style{
  padding: 0 1rem;
  margin: 0;
}

@media (max-width: 768px) {
  .container-content{
    margin: 0 0.5rem 18px 0.5rem;
  }
  .img-container{
    width: 3.75rem;
    height: 3.75rem;
  }
  .img-line{
    height: 2.3rem;
    width: 2px;
  }
  .top-line{
    height: 2.2rem;
    width: 2px;
  }
  .cycle{
    width: 0.6rem;
    height: 0.6rem;
  }
  .top-cycle{
    left: 15%;
  }
  .text-container{
    width: 7.5rem;
    height: 3.75rem;
  }
  .year-text{
    font-size: 0.75rem;
  }
  .another-year{
    font-size: 0.75rem;
  }
  .year-item{
    margin: 0.125rem 0.3rem 0 0;
  }
  .other-text{
    margin: 0.2rem 0.3rem 0 0.6rem;
    font-size: 0.4rem;
    line-height: 0.5rem;
  }
  .another-text{
    margin: 0.2rem 0 0 0.6rem;
  }
  .left-line{
    width: 2.2rem;
    height: 2px;
  }
  .right-line{
    width: 2.2rem;
    height: 2px;
  }
  .right-cycle{
    top: 42%;
  }
  .left-cycle{
    top: 42%;
  }
  .ul-style{
    padding: 0 0;
    margin: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1432px){
  .img-container{
    width: 240px;
    height: 170px;
  }
  .top-cycle{
    left: 21.5%;
  }
  .right-cycle{
    top: 45%;
  }
  .left-cycle{
    top: 45%;
    right: 98%;
  }
  .text-container{
    width: 290px;
    height: 170px;
  }
  .year-text{
    font-size: 1.4rem;
  }
  .another-year{
    font-size: 1.4rem;
  }
  .other-text{
    margin: 0 0 8px 8px;
    font-size: 0.7rem;
    line-height: 1.2rem;
  }
  .left-line{
    width: 5rem;
    height: 3px;
  }
  .ul-style{
    padding: 0.5rem 1rem;
    margin: 0;
  }
}
</style>
