<template>
  <div class="big-box">
    <div class="box-border">
      <div class="box-title">MAIN FUNCTION</div>
      <div class="box-text">主要功能</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainFunction'
}
</script>

<style scoped lang="less">
.big-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 510px;
  height: 140px;
}

@media (max-width: 768px) {
  .big-box {
    width: 240px;
    height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .space-img {
    width: 320px;
    height: auto;
  }
}

.box-border {
  position: relative;
  width: 100%;
  height: 80px;
  border: 2px solid #646262;
}

@media (max-width: 768px) {
  .box-border {
    height: 30px;
  }
}

.box-title {
  position: absolute;
  text-align: center;
  font-weight: 600;
  background-color: #ffffff;
  color: rgba(230, 0, 18, 1);
  font-size: 3rem;
  height: 3rem;
  bottom: 80%;
  transform: translateX(11%);
}

@media (max-width: 768px) {
  .box-title {
    width: 70%;
    font-size: 1.125rem;
    height: 16px;
    bottom: 80%;
    left: 8%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-title {
    font-size: 3rem;
    height: 3rem;
    bottom: 75%;
    transform: translateX(18%);
  }
}

.box-text {
  position: absolute;
  text-align: center;
  font-weight: 500;
  background-color: #FFFFFF;
  color: #646262;
  font-size: 2rem;
  line-height: 2.125rem;
  height: 26px;
  left: 50%;
  top: 95%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .box-text {
    font-size: 1rem;
    height: 21px;
    top: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .box-text {
  }
}
</style>
