<template>
  <div id="app">
    <official-index></official-index>
  </div>
</template>

<script>
import OfficialIndex from '@/pages/index.vue'

export default {
  name: 'App',
  components: {
    OfficialIndex
  }
}
</script>
<style scoped>
#app{
  margin: 0;
  padding: 0;
}
</style>
