<template>
  <div class="home">
    <div class="head-div">
      <div class="logo-div">
        <div><img class="logo-img" src="@/assets/images/logo.png" alt=""></div>
      </div>
      <div class="content-div">
        <div v-for="item in dataList" :key="item.id">
          <div class="subtitle" @mouseenter="showMenu = item.name" @mouseleave="showMenu = ''">
            <div @click="checkMenu(item)" :class="activeMenu === item.name ? 'activated': 'unactivated'">{{item.name}}</div>
            <div :class="showMenu === item.name ? 'showMenu' : 'subMenuList'">
              <ul class="ul-div">
                <li class="sub-title" v-for="(c,i) in item.children" :key="i" @click="checkMenu(c)">{{c.name}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { menuData } from '@/common-data/menu-data'
export default {
  name: 'Header',
  props: {
    activeBtn: {
      type: String,
      default: '首页'
    }
  },
  computed: {
    activeMenu () {
      return this.activeBtn
    }
  },
  data () {
    return {
      dataList: menuData,
      showMenu: ''
    }
  },
  methods: {
    checkMenu (item) {
      this.$emit('checkSuccess', item)
    }
  }
}
</script>
<style scoped lang="less">
.home{
  width: 100%;
  position: absolute;
  z-index: 9999;
}
.activated{
  color: #E60012 ;
}
.unactivated{
  color: #fff;
}
.head-div{
  background-size: cover;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: rgba(77,77,77,0.25);
  height: 240px;
  width: 100%;
}

@media (max-width: 768px) {
  .head-div{
    height: 110px;
    display: flex;
    align-items: start;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .head-div{
    height: 220px;
  }
}

.logo-div{
  width: 180px;
  height: 65px;
  margin-right: 150px;
  margin-top: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .logo-div{
    margin-right: 5px;
  }
}

@media (max-width: 768px) {
  .logo-div{
    width: 60px;
    height: 10px;
    margin: 15px 10px 0 0;
  }
}

.logo-img{
  width: 100%;
  height: auto;
}
.subtitle{
  font-family: 'PingFangSC-Regular', sans-serif;
  cursor: pointer;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  margin: 15px 20px;
}

@media (max-width: 768px) {
  .subtitle{
    font-weight: 300;
    font-size: 10px;
    margin: 8px 5px 2px 5px;
  }
}

.content-div{
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 20px
}

@media (max-width: 768px) {
  .content-div{
    margin-top: 10px
  }
}

.logo-text{
  text-align: center;
}
.logo-text-name{
  letter-spacing: 8px;
  font-weight: 900;
  font-size: 10px;
  margin-left: 10px;
  width: 90px;
}
.foot-text{
  letter-spacing: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 20px;
}

.sub-title{
  font-family: 'PingFangSC-Light', sans-serif;
  margin: 10px 0;
  font-size: 15px;
}

@media (max-width: 768px) {
  .sub-title{
    margin: 3px 0;
    font-size: 7px;
    cursor: pointer;
  }
}

.subMenuList{
  display: none;
  overflow: hidden;
  transition: opacity .1s ease;
}

.showMenu{
  display: block;
  overflow: visible;
  transition: opacity .1s ease;
}

.ul-div{
  list-style-type: none;
  margin: 5px 0 ;
  padding: 0 ;
  text-align: center;
}

@media (max-width: 768px) {
  margin: 0;
}

/* 媒体查询，针对小屏幕（如手机）进行样式调整 */
</style>
