<template>
  <div class="container">
    <div class="images-container">
      <div class="image-grid-left">
        <div v-for="(img,index) in item.images" :key="index+img" :class="index === 2 ? 'special-item' : 'image-div'" >
          <img :src=img alt="" />
        </div>
      </div>
      <div class="department-left">
        <div class="department-img">
          <img class="department-image" :src=item.img alt="" />
        </div>
        <div class="description-div-left">
          <div class="text-container">
            <div class="name-div">
              <div class="text-name">{{item.name}}:</div>
            </div>
            <div class="description">
              <div>{{item.info}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'department-left',
  props: ['item']
}
</script>

<style scoped>
.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
}

@media (max-width: 768px) {
  .container{
    margin-bottom: 30px;
  }
}

.images-container {
  display: flex;
  justify-content: center;
  align-items: start;
}

@media (max-width: 768px) {
  .images-container {
    justify-content: start;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .images-container {
    justify-content: start;
  }
}

.department-img{
  width: 589px;
  height: 350px;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .department-img{
    width: 10.9rem;
    height: 7rem;
    margin-bottom: 0.5rem;
  }
  .department-img img{
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .department-img{
    width: 24.3rem;
    height: 16rem;
    margin-bottom: 1rem;
  }
  .department-img img{
    width: 100%;
    height: 100%;
  }
}

.description-div-left{
  width: 590px;
  height: 310px;
  background: #E60012;
  border-radius: 0 0 0 47px;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
}

@media (max-width: 768px) {
  .description-div-left{
    width: 10.9rem;
    height: 7rem;
    border-radius: 0 0 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .description-div-left{
    width: 24.3rem;
    height: 15.8rem;
    border-radius: 0 0 0 2rem;
  }
}

.text-container{
  padding-right: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 95%;
  height: 80%;
}
@media (max-width: 768px) {
  .text-container{
    padding-right: 0.3rem;
    padding-left: 0.3rem;
  }
}

.image-grid-left{
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2列，每列等宽 */
  grid-template-rows: repeat(3, 1fr); /* 3行，每行等高 */
  margin-right: 16px;
  gap: 1.25rem;
}

@media (max-width: 768px) {
  .image-grid-left{
    margin-right: 6px;
    gap: 0.5rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .image-grid-left{
    margin-right: 10px;
    gap: 0.5rem;
  }
}

.special-item{
  grid-column: span 2;
}
@media (max-width: 768px) {
  .special-item img{
    width: 11.2rem;
    height: 4.25rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .special-item img{
    width: 26.625rem;
    height: 10.625rem;
  }
}

.image-div{
  width: 287px;
  height: 210px;
}

@media (max-width: 768px) {
  .image-div{
    width: 5.3rem;
    height: 4.375rem;
  }
  .image-div img{
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .image-div{
    width: 210px;
    height: 160px;
  }
  .image-div img{
    width: 100%;
    height: 100%;
  }
}

.name-div{
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.text-name{
  font-weight: 600;
  color: #FFFFFF;
  font-size: 2rem;
  line-height: 2.5rem;
}

@media (max-width: 768px) {
  .text-name{
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .text-name{
    font-size: 1.75rem;
    line-height: 1rem;
  }
}

.english-text{
  font-weight: 100;
  color: #FFFFFF;
  line-height: 26px;
  font-size: 20px;
}

@media (max-width: 768px) {
  .english-text{
    line-height: 9px;
    font-size: 7px;
  }
}

.rdquo{
  font-weight: normal;
  font-size: 93px;
  color: #FFFFFF;
  line-height: 121px;
}

@media (max-width: 768px) {
  .rdquo{
    line-height: 31px;
    font-size: 31px;
  }
}

.description{
  font-weight: 400;
  color: #FFFFFF;
  font-size: 1rem;
  line-height: 2rem;
}

@media (max-width: 768px) {
  .description{
    font-size: 0.5rem;
    line-height: 0.8rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px){
  .description{
    font-size: 14px;
    line-height: 28px;
  }
}

</style>
