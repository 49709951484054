export function isMobile () {
  if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    // 手机标识
    return 'phone'
  }
  return 'computer'
}
// 根据名称，跳转到该锚点
export function handleScrollToAnchor (anchorName) {
  const element = document.getElementById(anchorName)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
