export const menuData = [
  {
    id: 1,
    name: '首页',
    eName: 'index',
    children: []
  },
  {
    id: 2,
    name: '关于我们',
    eName: 'aboutUs',
    showChildren: false,
    children: [{
      name: '公司简介',
      parentEName: 'aboutUs',
      parentName: '关于我们'
    }, {
      name: '漫调团队',
      parentEName: 'aboutUs',
      parentName: '关于我们'
    }, {
      name: '漫调荣誉',
      parentEName: 'aboutUs',
      parentName: '关于我们'
    }, {
      name: '企业历程',
      parentEName: 'aboutUs',
      parentName: '关于我们'
    }]
  },
  {
    id: 3,
    name: '漫调园区',
    eName: 'park',
    showChildren: false,
    children: [{
      name: '园区介绍',
      parentEName: 'park',
      parentName: '漫调园区'
    }, {
      name: '园区展示',
      parentEName: 'park',
      parentName: '漫调园区'
    }]
  },
  {
    id: 4,
    name: '漫调服务',
    eName: 'service',
    showChildren: false,
    children: [{
      name: '服务特色',
      parentEName: 'service',
      parentName: '漫调服务'
    }, {
      name: '业务范围',
      parentEName: 'service',
      parentName: '漫调服务'
    }, {
      name: '服务承诺',
      parentEName: 'service',
      parentName: '漫调服务'
    }, {
      name: '服务案例',
      parentEName: 'service',
      parentName: '漫调服务'
    }]
  },
  {
    id: 5,
    name: '爱漫调APP',
    eName: 'app',
    showChildren: false,
    children: [{
      name: 'APP简介',
      parentEName: 'app',
      parentName: '爱漫调APP'
    }, {
      name: '主要功能',
      parentEName: 'app',
      parentName: '爱漫调APP'
    }]
  },
  {
    id: 6,
    name: '漫调资讯',
    eName: 'information',
    showChildren: false,
    children: [{
      name: '资讯',
      parentEName: 'information',
      parentName: '漫调资讯'
    }, {
      name: '活动',
      parentEName: 'information',
      parentName: '漫调资讯'
    }, {
      name: '动态',
      parentEName: 'information',
      parentName: '漫调资讯'
    }]
  }]

export const experienceDataList = [
  {
    id: 1,
    year: '2015',
    background: 'red',
    img: require('@/assets/images/about-us/experience1.png'),
    children: ['漫调e空间正式成立', '获首批重庆市众创空间认定'],
    yearTop: true,
    rightLine: true
  },
  {
    id: 2,
    year: '2016',
    background: 'gray',
    img: require('@/assets/images/about-us/experience2.png'),
    children: ['成立大数据公司，开启智慧孵化布局 ', '在重庆股转中心正式挂牌', '成为重庆市众创空间联盟理事单位', '获批为“渝北区微型企业孵化园”', '龙塔分部成立', '入驻企业数量突破300家'],
    yearTop: false,
    leftCycle: true,
    bottomLine: true
  },
  {
    id: 4,
    year: '2018',
    background: 'gray',
    img: require('@/assets/images/about-us/experience3.png'),
    children: ['受邀参加首届智博会', '获批为重庆市创业孵化基地', '获批为“重庆市中小企业公共服务示范平台“', '杨家坪分部、汽博分部成立', '入驻企业数量突破800家'],
    yearTop: true,
    rightCycle: true,
    bottomLine: true
  },
  {
    id: 3,
    year: '2017',
    background: 'red',
    img: require('@/assets/images/about-us/experience4.png'),
    children: ['获批为渝北区“上市培育库”企业', '获批为“国家备案众创空间”', '获批为“重庆市信息化建设示范单位’', '获批为重庆市首批“青创空间’', '北环分部成立', '累计服务企业数量超过1800家次'],
    yearTop: false,
    leftLine: true,
    topCycle: true
  },
  {
    id: 5,
    year: '2019',
    background: 'red',
    img: require('@/assets/images/about-us/experience5.png'),
    children: ['自主研发的“智慧办公应用软件”和“共享办公管理系统”通过知识产权管理体系认证', '纳入重庆市“专精特新”企业培育库', '获批为“众创空间知识产权服务工作站”', '二郎分部成立', '入驻企业数量突破1000家'],
    yearTop: true,
    topCycle: true,
    rightLine: true
  },
  {
    id: 6,
    year: '2020',
    background: 'gray',
    img: require('@/assets/images/about-us/experience6.png'),
    children: ['获“中小企业上云”项目获得市经信委支持', '获“2020百家特色载体”称号', '获评“科技创业孵化贡献奖”', '获批为“重庆市知识产权优势企业”', '綦江分部成立', '累计服务企业超10000家次'],
    yearTop: false,
    leftCycle: true,
    bottomLine: true
  },
  {
    id: 8,
    year: '2022',
    background: 'gray',
    img: require('@/assets/images/about-us/experience7.png'),
    children: ['获批为“重庆市渝北区服务业发展成长企业”', '获批为2021年度A级纳税企业', '企业服务产品入选“重庆好服务”', '大数据公司获批为国家高新技术企业', '获批为“渝北区重点软件产业楼宇”', '累计服务企业超20000家次'],
    yearTop: true,
    bottomLine: true,
    rightCycle: true
  },
  {
    id: 7,
    year: '2021',
    background: 'red',
    img: require('@/assets/images/about-us/experience8.png'),
    children: ['获批为“重庆市科技企业孵化器”', '获批为“2020年度A级纳税企业”', '获批为“国家中小企业公共服务示范平台”', '入驻企业突破1200家'],
    yearTop: false,
    topCycle: true,
    leftLine: true
  },
  {
    id: 9,
    year: '2023',
    background: 'red',
    img: require('@/assets/images/about-us/experience9.png'),
    children: ['金星园区成立', '获批为2022年度A级纳税企业', '翼空间公司获批为重庆“专精特新”中小企业', '翼空间公司获批为“国家高新技术企业”', '入驻企业突破1400家'],
    yearTop: true,
    topCycle: true,
    rightLine: true
  },
  {
    id: 10,
    year: '2024',
    background: 'gray',
    img: require('@/assets/images/about-us/experience10.png'),
    children: ['两江分部成立', '获“重庆市最受欢迎公共服务示范平台”称号', '连锁众创运营案例入选日本大学商学部硕士课程', '获批为“2023年度A级纳税企业”', '获第三届“创享渝北”创业服务大赛第二名', '漫调e空间科学技术协会成立'],
    yearTop: false,
    leftCycle: true
  }
]

export const phoneExperienceDataList = [
  {
    id: 1,
    year: '2015',
    background: 'red',
    img: require('@/assets/images/about-us/experience1.png'),
    children: ['漫调e空间正式成立', '获首批重庆市众创空间认定']
    // bottomLine: true
  },
  {
    id: 2,
    year: '2016',
    background: 'gray',
    img: require('@/assets/images/about-us/experience2.png'),
    children: ['成立大数据公司，开启智慧孵化布局 ', '在重庆股转中心正式挂牌', '成为重庆市众创空间联盟理事单位', '获批为“渝北区微型企业孵化园”', '龙塔分部成立', '入驻企业数量突破300家'],
    yearTop: false
    // topCycle: true,
    // bottomLine: true
  },
  {
    id: 3,
    year: '2017',
    background: 'red',
    img: require('@/assets/images/about-us/experience4.png'),
    children: ['获批为渝北区“上市培育库”企业', '获批为“国家备案众创空间”', '获批为“重庆市信息化建设示范单位’', '获批为重庆市首批“青创空间’', '北环分部成立', '累计服务企业数量超过1800家次'],
    yearTop: false
    // topCycle: true,
    // bottomLine: true
  },
  {
    id: 4,
    year: '2018',
    background: 'gray',
    img: require('@/assets/images/about-us/experience3.png'),
    children: ['受邀参加首届智博会', '获批为重庆市创业孵化基地', '获批为“重庆市中小企业公共服务示范平台“', '杨家坪分部、汽博分部成立', '入驻企业数量突破800家'],
    yearTop: true
    // topCycle: true,
    // bottomLine: true
  },
  {
    id: 5,
    year: '2019',
    background: 'red',
    img: require('@/assets/images/about-us/experience5.png'),
    children: ['自主研发的“智慧办公应用软件”和“共享办公管理系统”通过知识产权管理体系认证', '纳入重庆市“专精特新”企业培育库', '获批为“众创空间知识产权服务工作站”', '二郎分部成立', '入驻企业数量突破1000家'],
    yearTop: true
    // topCycle: true,
    // bottomLine: true
  },
  {
    id: 6,
    year: '2020',
    background: 'gray',
    img: require('@/assets/images/about-us/experience6.png'),
    children: ['获“中小企业上云”项目获得市经信委支持', '获“2020百家特色载体”称号', '获评“科技创业孵化贡献奖”', '获批为“重庆市知识产权优势企业”', '綦江分部成立', '累计服务企业超10000家次'],
    yearTop: false
    // topCycle: true,
    // bottomLine: true
  },
  {
    id: 7,
    year: '2021',
    background: 'red',
    img: require('@/assets/images/about-us/experience8.png'),
    children: ['获批为“重庆市科技企业孵化器”', '获批为“2020年度A级纳税企业”', '获批为“国家中小企业公共服务示范平台”', '入驻企业突破1200家'],
    yearTop: false
    // topCycle: true,
    // bottomLine: true
  },
  {
    id: 8,
    year: '2022',
    background: 'gray',
    img: require('@/assets/images/about-us/experience7.png'),
    children: ['获批为“重庆市渝北区服务业发展成长企业”', '获批为2021年度A级纳税企业', '企业服务产品入选“重庆好服务”', '大数据公司获批为国家高新技术企业', '获批为“渝北区重点软件产业楼宇”', '累计服务企业超20000家次'],
    yearTop: true
    // topCycle: true,
    // bottomLine: true
  },
  {
    id: 9,
    year: '2023',
    background: 'red',
    img: require('@/assets/images/about-us/experience9.png'),
    children: ['金星园区成立', '获批为2022年度A级纳税企业', '翼空间公司获批为重庆“专精特新”中小企业', '翼空间公司获批为“国家高新技术企业”', '入驻企业突破1400家'],
    yearTop: true
    // topCycle: true,
    // bottomLine: true
  },
  {
    id: 10,
    year: '2024',
    background: 'gray',
    img: require('@/assets/images/about-us/experience10.png'),
    children: ['两江分部成立', '获“重庆市最受欢迎公共服务示范平台”称号', '连锁众创运营案例入选日本大学商学部硕士课程', '获批为“2023年度A级纳税企业”', '获第三届“创享渝北”创业服务大赛第二名', '漫调e空间科学技术协会成立']
    // topCycle: true
  }
]
