<template>
  <div class="app-container" id="漫调园区">
    <div class="home-bg bg-image"></div>
    <div class="work-life-boxing" id="园区介绍">
      <div class="work-life">
        <div class="english-text">MANDIAO&nbsp;E&nbsp;SPACE</div>
        <div class="life-text">快乐工作·漫调生活</div>
      </div>
      <div class="info-text">
          翼空间（重庆）创业孵化器有限公司成立于2015年，是一家以智慧办公空间为载体基础，以科技型企业发展为服务路径，以智慧精准服务为抓手，致力于打造围绕小微型企业的经营需求链提供全要素、低成本、便利化、专业化企业服务的科技型孵化平台企业。
          经过十年的深耕，已建成覆盖渝北区、两江新区、九龙坡区、綦江区区域内的八个连锁自营园区。
      </div>
    </div>
    <div class="business-boxing" id="园区展示">
      <div class="images-div">
        <div v-for="item in departments" :key="item.id">
<!--          <department-right v-if="item.id%2===1" :item="item"></department-right>-->
<!--          <department-left v-else :item="item"></department-left>-->
          <component :is="item.id % 2 === 1 ? 'DepartmentRight' : 'DepartmentLeft'" :item="item"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleScrollToAnchor } from '@/util/tool'
import DepartmentLeft from '@/components/content/components/department-left.vue'
import DepartmentRight from '@/components/content/components/department-right.vue'
import Left from '@/components/content/components/left.vue'
export default {
  components: { DepartmentLeft, DepartmentRight, Left },
  name: 'park',
  props: {
    jumpName: {
      type: String
    }
  },
  watch: {
    jumpName: {
      handler (val) {
        if (val) this.handleScrollToAnchor(val)
      },
      immediate: true
    }
  },
  mounted () {
    this.hasMounted = true
    if (this.jumpName) {
      this.handleScrollToAnchor(this.jumpName)
    }
  },
  data () {
    return {
      hasMounted: false,
      departments: [
        { id: 1, name: '金星总部', letters: 'JinXing Head Office', info: '国家中小企业公共服务示范平台、重庆市众创空间', location: '重庆市两江新区星光大道60号金星科技大厦C栋5层', product: '大数据、人工智能、智能制造等新兴产业', images: [require('@/assets/images/park/department/venus/show1.png'), require('@/assets/images/park/department/venus/show2.png'), require('@/assets/images/park/department/venus/show3.png'), require('@/assets/images/park/department/venus/show4.png'), require('@/assets/images/park/department/venus/show5.png')], img: require('@/assets/images/park/department/venus/introduce.png') },
        { id: 2, name: '两江分部', letters: 'LiangJiang Branch', info: '重庆新科数字科技孵化器', location: '重庆市两江新区金开大道互联网产业园11栋', product: '互联网IT、智能网联、智能硬件等高新产业', images: [require('@/assets/images/park/department/two-rivers/show1.png'), require('@/assets/images/park/department/two-rivers/show2.png'), require('@/assets/images/park/department/two-rivers/show3.png'), require('@/assets/images/park/department/two-rivers/show4.png'), require('@/assets/images/park/department/two-rivers/show5.png')], img: require('@/assets/images/park/department/two-rivers/bg.png') },
        { id: 3, name: '北环分部', letters: 'BeiHuan Branch', info: '国家备案众创空间、重庆市科技企业孵化器、渝北区满天星示范楼宇', location: '重庆市渝北区龙山街道龙山一路100号圣地大厦', product: '软件和信息服务业、新零售、新媒体等新兴产业', images: [require('@/assets/images/park/department/north-loop/show1.png'), require('@/assets/images/park/department/north-loop/show2.png'), require('@/assets/images/park/department/north-loop/show3.png'), require('@/assets/images/park/department/north-loop/show4.png'), require('@/assets/images/park/department/north-loop/show5.png')], img: require('@/assets/images/park/department/north-loop/bg.png') },
        { id: 4, name: '龙塔分部', letters: 'LongTa Branch', info: '国家备案众创空间、重庆市创业孵化基地、重庆市科技企业孵化器', location: '重庆市渝北区龙塔街道洋河东路37号长都大厦', product: '文创产业、软信行业、新媒体等文创科技产业', images: [require('@/assets/images/park/department/dragon-tower/show1.png'), require('@/assets/images/park/department/dragon-tower/show2.png'), require('@/assets/images/park/department/dragon-tower/show3.png'), require('@/assets/images/park/department/dragon-tower/show4.png'), require('@/assets/images/park/department/dragon-tower/show5.png')], img: require('@/assets/images/park/department/dragon-tower/bg.png') },
        { id: 5, name: '汽博分部', letters: 'QiBo Branch', info: '重庆市众创空间', location: '重庆市两江新区金渝大道68号4栋23层', product: '新媒体、智慧物联、文创设计等创新型产业', images: [require('@/assets/images/park/department/autobo/show1.png'), require('@/assets/images/park/department/autobo/show2.png'), require('@/assets/images/park/department/autobo/show3.png'), require('@/assets/images/park/department/autobo/show4.png'), require('@/assets/images/park/department/autobo/show5.png')], img: require('@/assets/images/park/department/autobo/bg.png') },
        { id: 6, name: '二郎分部', letters: 'ErLang Branch', info: '国家备案众创空间、重庆市创业孵化基地、九龙坡区满天星楼宇、九龙坡区服务工作站', location: '重庆市九龙坡区火炬大道5号九悦荟4栋4层', product: '软信产业、跨境电商等科技产业', images: [require('@/assets/images/park/department/erlang/show1.png'), require('@/assets/images/park/department/erlang/show2.png'), require('@/assets/images/park/department/erlang/show3.png'), require('@/assets/images/park/department/erlang/show4.png'), require('@/assets/images/park/department/erlang/show5.png')], img: require('@/assets/images/park/department/erlang/bg.png') },
        { id: 7, name: '杨家坪分部', letters: 'YangJiaPing Branch', info: '国家备案众创空间、重庆市创业孵化基地、九龙坡区满天星楼宇、九龙坡区服务工作站', location: '重庆市九龙坡区杨家坪西郊路19号都市桃源2层', product: '软信产业、新零售、新媒体等科技产业', images: [require('@/assets/images/park/department/yangjiaping/show1.png'), require('@/assets/images/park/department/yangjiaping/show2.png'), require('@/assets/images/park/department/yangjiaping/show3.png'), require('@/assets/images/park/department/yangjiaping/show4.png'), require('@/assets/images/park/department/yangjiaping/show5.png')], img: require('@/assets/images/park/department/yangjiaping/bg.png') },
        { id: 8, name: '綦江分部', letters: 'QiJiang Branch', info: '国家备案众创空间', location: '地理位置：重庆市綦江区文龙街道九龙大道凯旋天街47号2层', product: '乡村振兴、农旅电商行业等文旅产业', images: [require('@/assets/images/park/department/venus/show1.png'), require('@/assets/images/park/department/venus/show2.png'), require('@/assets/images/park/department/venus/show3.png'), require('@/assets/images/park/department/venus/show4.png'), require('@/assets/images/park/department/venus/show5.png')], img: require('@/assets/images/park/department/qijiang/bg.png') }
      ]
    }
  },
  methods: {
    handleScrollToAnchor
  }
}
</script>
<style scoped>
@import "@/assets/styles/common.css";
.bg-image{
  background: url('https://imandiao.oss-cn-beijing.aliyuncs.com/website/images/park/background.jpg') no-repeat center;
  background-size: cover;
  object-fit: cover;
}

.work-life-boxing{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #E60012 ;
}

.work-life{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .work-life{
    height: 80px;
  }
}

.english-text{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 118px;
  font-size: 110px;
  width: 100%;
  color: #fff;
  font-weight: 400;
  opacity: 0.1;
}

@media (max-width: 768px) {
  .english-text{
    font-size: 2rem;
    height: 7.37rem;
  }
}

.life-text{
  position: absolute;
  color: #FFFFFF;
  font-size: 2rem;
  letter-spacing: 1rem;
  top: 40%;
}

@media (max-width: 768px) {
  .life-text{
    font-size: 1rem;
    letter-spacing: 0.5rem;
    top: 35%;
  }
}

.info-text{
  text-indent: 2em;
  margin-bottom: 40px;
  width: 80%;
  font-weight: 300;
  font-size: 23px;
  color: #FFFFFF;
  line-height: 37px;
}

@media (max-width: 768px) {
  .info-text{
    font-size: 12px;
    line-height: 20px;
    width: 90%;
  }
}

.business-boxing{
  background:rgba(256, 256, 256, 1);
}
.images-div{
  padding-top: 80px;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
}
</style>
