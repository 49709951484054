<template>
  <div class="app-container" id="关于我们">
    <div class="home-bg bg-image"></div>
    <div class="work-life-boxing">
      <div class="business-border" id="公司简介">
        <border-box title="INTRODUCTION" text="公司简介" background-color="normal" title-color="red"></border-box>
      </div>
      <div class="introduce-div">
        <p>漫调e空间成立于2015年，是一家以智慧办公空间为载体基础，以科技型企业发展为服务路径，以智慧精准服务为抓手，致力于打造围绕小微型企业的经营需求链提供全要素、低成本、便利化、专业化企业服务的科技型孵化平台企业。经过十年的深耕，已建成覆盖重庆渝北区、两江新区、九龙坡区、綦江区的八个连锁园区。</p>
        <p>漫调始终厚植“服务有效率、服务有质量、服务有落实”的“小二”精神，深耕细作“创新驱动、内外联动、服务托底”模式，紧扣智慧化、精准化、精细化的关键抓手，搭建软件与硬件服务双驱并行，基于企业大数据与信息端、服务端、市场端互联互通的数字创业生态，实现打造供应链、投融资链和技术成果转化链等各方面商业社交平台和资源配置平台。漫调自主研发了“爱漫调”服务APP，实施“线上+线下”双线并行，精准的为小微型企业提供创业指导、财税、工商管理、政策咨询、人力培训、法律风险防控等“全科”专业服务。</p>
        <p>作为西部孵化体系内的先行企业，漫道紧跟“科技改变企业”思路，率先推行“定制式办公空间+智慧专业化服务”服务体系，着力构建“智慧+精准”服务的数智生态服务体系，着力践行中小企业数字化转型发展助力数字化、智慧化提档升级，将漫调e空间建设成为西部独具特色、有影响力、可持续发展的科技型企业智慧孵化园区。</p>
        <p>漫调e空间先后被认定为国家中小企业公共服务示范平台、国家备案众创空间、国家高新技术企业、国家科技型中小企业、重庆市“专精特新”企业、重庆市级创业孵化基地、重庆市规模以上企业、重庆市创新型中小企业、重庆市知识产权优势企业、重庆市信息化示范单位,并连续4年获得国家A级纳税企业。</p>
<!--        <div class="introduce-self">个 人 荣 誉</div>-->
<!--        <div class="introduce-info">-->
<!--          <ul>-->
<!--            <li class="introduce-honor" v-for="(item, index) in honorList" :key="index">{{item}}</li>-->
<!--          </ul>-->
<!--        </div>-->
      </div>
    </div>
    <div class="business-boxing">
<!--      <div class="founder-message">-->
<!--        <div>董事长寄语：</div>-->
<!--        <div class="message-info">-->
<!--科技发展日新月异，改变了我们的生活方式和工作方式，带来了便利高效的全新体验；同时我们认知的局限，思想的浮躁与科技信息经济的快速发展之间的矛盾也越发显现。-->
<!--拥抱创新，迎接改变，敢于挑战是这个时代的主旋律。漫调从一出生就在一个陌生的领域探索追寻，这个过程也将一直持续。服务没有止境，草根创业者需要漫调，这是我们的责任，也是我们追寻的梦想&#45;&#45;让草根创业者不再孤独。-->
<!--漫调将成长为一家受人尊敬的企业，一家员工引以为荣的企业，一家成人达己的企业。</div>-->
<!--        <div style="text-align: right">——这就是漫调</div>-->
<!--      </div>-->
      <div class="business-border" id="漫调团队">
        <border-box title="OUR TEAM" text="漫调团队" title-color="red"></border-box>
      </div>
      <div class="our-team">
        <our-team :item="teamData"></our-team>
      </div>
    </div>
    <div class="enterprise-box">
      <div class="business-border" id="漫调荣誉">
        <border-box title="OUR HONOR" text="漫调荣誉" background-color="normal" title-color="red"></border-box>
        <div class="honor-div">
          <img src="@/assets/images/about-us/honor.png" alt="" />
        </div>
      </div>
    </div>
    <div class="business-boxing">
      <div class="business-border" id="企业历程">
        <border-box title="EXPERIENCE" text="漫调历程" border-color="normal" title-color="red"></border-box>
      </div>
      <div v-if="requestType === 'phone' || currentWidth" class="phone-images-div">
        <phone-experience-data v-for="item in phoneExperienceDataList" :key="item.id" :item="item"></phone-experience-data>
      </div>
      <div v-else class="images-div">
        <experience-data v-for="item in experienceList" :key="item.id" :item="item"></experience-data>
      </div>
<!--      <div class="contact-container" id="联系我们">-->
<!--        <border-box title="CONTACTUS" text="联系我们" border-color="normal" title-color="red"></border-box>-->
<!--      </div>-->
<!--      <div class="bottom-banner">-->
<!--        <div class="contact-info">-->
<!--          <div>总部地址：重庆市两江新区星光大道60号金星科技大厦C栋5层</div>-->
<!--          <div>联系电话：023-86228000</div>-->
<!--          <div>邮箱：mandiao@iman</div>-->
<!--        </div>-->
<!--        <div class="download-div">-->
<!--          <img src="@/assets/images/about-us/code.png" alt="" />-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import { handleScrollToAnchor, isMobile } from '@/util/tool'
import { experienceDataList, phoneExperienceDataList } from '@/common-data/menu-data'
import BorderBox from '@/components/common/border-box.vue'
import ExperienceData from '@/components/content/components/experience-data.vue'
import OurTeam from '@/components/content/components/our-team.vue'
import PhoneExperienceData from '@/components/content/components/phone-experience-data.vue'
export default {
  components: { PhoneExperienceData, BorderBox, ExperienceData, OurTeam },
  name: 'aboutUs',
  props: {
    jumpName: {
      type: String
    }
  },
  watch: {
    jumpName: {
      handler (val) {
        if (this.hasMounted && val) this.handleScrollToAnchor(val)
      },
      immediate: true
    }
  },
  created () {
    this.requestType = this.isMobile()
  },
  mounted () {
    this.hasMounted = true
    if (this.jumpName) {
      this.handleScrollToAnchor(this.jumpName)
    }
    window.addEventListener('resize', this.handleResize)
  },
  computed: {
    currentWidth () {
      return this.windowWidth < 768
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  data () {
    return {
      requestType: '',
      handleScrollToAnchor,
      windowWidth: window.innerWidth,
      hasMounted: false,
      honorList: [
        '翼空间(重庆)创业孵化器有限公司董事长 ',
        '金牛·渝北就业创业导师',
        '渝北区中级创业导师',
        '重庆英才·创新创业领军人才',
        '渝北区乡创振兴人才',
        '重庆创业领军企业家钻石奖',
        '科技创业导师贡献奖'
      ],
      experienceList: experienceDataList,
      phoneExperienceDataList: phoneExperienceDataList,
      teamData: { id: 8, name: '漫调人', info: '宛如一支精锐之师，他们以创新为矛，以智慧为盾，在市场的疆场上冲锋陷阵。他们拥有广阔的格局，不拘泥于眼前的成就，而是不断挑战自我，追求更高的目标。他们相互激励，共同成长，用无尽的创造力开拓未来，成为行业的佼佼者。', location: '地理位置：重庆市綦江区文龙街道九龙大道凯旋天街47号2层', images: [require('@/assets/images/our-team/show1.png'), require('@/assets/images/our-team/show2.png'), require('@/assets/images/our-team/show3.png'), require('@/assets/images/our-team/show4.png'), require('@/assets/images/our-team/show5.png')], img: require('@/assets/images/our-team/bg.png') }
    }
  },
  methods: {
    isMobile,
    handleResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>
<style scoped>
@import "@/assets/styles/common.css";

.bg-image{
  background: url('https://imandiao.oss-cn-beijing.aliyuncs.com/website/images/about-us/banner.png') center no-repeat;
  background-size: cover;
  object-fit: cover;
}

.work-life-boxing{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
}
@media (max-width: 768px) {
  .work-life-boxing{
    margin-top: 3px;

  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .work-life-boxing{
    margin-top: 3px;
  }
}

.introduce-self{
  width: 19px;
  height: 91px;
  font-weight: 600;
  font-size: 1.2rem;
  color: #FFFFFF;
  line-height: 24px;
}

@media (max-width: 768px){
  .introduce-self{
    width: 9px;
    height: 45px;
    font-weight: 600;
    font-size: 0.5rem;
    color: #FFFFFF;
    line-height: 0.8rem;
  }
}

@media (max-width: 768px) {
  .introduce-honor{
    font-size: 0.5rem;
    line-height: 0.6rem;
  }
}

.introduce-info{
  height: 193px;
  font-weight: 300;
  font-size: 1.2rem;
  color: #FFFFFF;
  line-height: 29px;
}

@media (max-width: 768px){
  .introduce-info{
    height: 10px;
    font-weight: 150;
    font-size: 0.375rem;
    line-height: 0.6rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .introduce-info{
    line-height: 25px;
  }
}

.introduce-info ul{
  list-style-type: none;
  margin: 0 0 10px 20px;
  padding: 0;
}

.founder-message{
  margin: 56px 0 0 0;
  width: 75%;
  font-weight: 300;
  font-size: 1.2rem;
  color: #000000;
  line-height: 2rem;
}

@media (max-width: 768px) {
  .founder-message{
    width: 85%;
    margin: 10px 0 20px 0;
    font-size: 0.56rem;
    line-height: 1.2rem;
  }
}

.message-info{
  text-indent: 2em;
}

.our-team{
  margin-top: 40px;
  display: flex;
  justify-items: center;
  align-items: center;
}

.introduce-div{
  padding: 3rem 2.8rem;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  font-weight: 300;
  font-size: 1.4rem;
  color: #606060;
  line-height: 38px;
  text-indent: 2em;
}
.introduce-div p{
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .introduce-div{
    padding: 0 1rem;
    font-weight: 300;
    font-size: 0.8rem;
    color: #606060;
    line-height: 1.6rem;
  }
}

.business-boxing{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background:rgba(256, 256, 256, 1);
}
.business-border{
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .business-border{
    padding-top: 15px;
  }
}
.images-div{
  margin: 80px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2列，每列等宽 */
  grid-template-rows: repeat(2, 1fr); /* 2行，每行等高 */
  gap: 8.6rem;
}

@media (max-width: 768px) {
  .images-div{
    margin: 20px 0;
    gap: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1432px)  {
  .images-div{
    margin: 40px 0;
    gap: 50px;
  }
}

.phone-images-div{
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .phone-images-div{
    margin: 20px 0;
  }
}

.contact-container{
  padding-top: 139px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .contact-container{
    padding-top: 15px;
  }
}

.enterprise-box{
  width: 100%;
  margin-bottom: 70px;
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
}

.honor-div{
  margin: 70px 0 0 0;
}

@media (max-width: 768px) {
  .honor-div{
    margin: 48px 0 0 0;
  }
}

.honor-div img{
  width: 100%;
  height: 100%;
}

.bottom-banner{
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 114px 0 0 0;
  width: 100%;
  background: #E60012;
}
@media (max-width: 768px) {
  .bottom-banner{
    margin: 40px 0 0 0;
  }
}

.contact-info{
  margin: 40px 0 40px 28%;
  font-weight: 300;
  font-size: 1.5rem;
  color: #FFFFFF;
  line-height: 42px;
}

@media (max-width: 768px) {
  .contact-info{
    margin: 10px 0 10px 40px;
    font-size: 0.5rem;
    line-height: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .contact-info{
    margin: 10px 0 10px 10%;
    font-size: 1.125rem;
    line-height: 36px;
  }
}

.download-div{
  position: absolute;
  border: 1px dashed black;
  width: 247px;
  height: 247px;
  bottom: 10%;
  left: 70%;
}

@media (max-width: 768px) {
  .download-div{
    width: 60px;
    height: 60px;
    left: 81%;
    bottom: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .download-div{
    width: 180px;
    height: 180px;
    bottom: 40%;
  }
}

.download-div img{
  width: 100%;
  height: 100%;
}

</style>
