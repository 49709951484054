<template>
  <div class="app-container" id="首页">
    <div class="home-bg bg-image"></div>
    <div class="work-life-boxing">
      <div class="work-life">
        <div class="english-text">MANDIAO&nbsp;E&nbsp;SPACE</div>
        <div class="life-text">快乐工作·漫调生活</div>
      </div>
      <div class="info-text">漫调e空间作为国家备案众创空间、国家中小企业公共服务示范平台，以打造“有温度的自由办公空间”为目标，以“低成本、高价值、便利化” 的办公空间为核心，构建基于智慧孵化的商业社交平台和资源配置平台。</div>
      <div class="small-title">{{description.toUpperCase()}}</div>
    </div>
    <introduce-business></introduce-business>
    <div class="case-box">
      <div class="case-box-start">
        <div class="case-title">
          <border-box-case></border-box-case>
        </div>
      </div>
      <div class="show-images">
        <case-images></case-images>
      </div>
    </div>
    <div class="enterprise-box">
      <div class="business-border">
        <enterprise></enterprise>
      </div>
      <div class="trademark-div">
        <div style="width: 100%; display: flex;flex-direction: column;justify-content: center; align-items: center"><img :src="`${imageUrlDomain}images/trademark.png`" alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleScrollToAnchor } from '@/util/tool'
import BorderBoxCase from '@/components/common/border-box-case.vue'
import CaseImages from '@/components/content/components/case-images.vue'
import Enterprise from '@/components/common/enterprise.vue'
import IntroduceBusiness from '@/components/content/components/introduce-business.vue'
import { imageUrlDomain } from '@/common-data/image-domain'
export default {
  components: { BorderBoxCase, IntroduceBusiness, CaseImages, Enterprise },
  name: 'homePage',
  props: {
    jumpName: {
      type: String
    }
  },
  watch: {
    jumpName: {
      handler (val) {
        if (this.hasMounted && val) this.handleScrollToAnchor(val)
      },
      immediate: true
    }
  },
  mounted () {
    this.hasMounted = true
    if (this.jumpName) {
      this.handleScrollToAnchor(this.jumpName)
    }
  },
  data () {
    return {
      imageUrlDomain,
      description: 'Enjoy work,enjoy your life in Mandiao',
      handleScrollToAnchor,
      hasMounted: false,
      imagesData: []
    }
  }
}
</script>
<style scoped>
@import "@/assets/styles/common.css";
.bg-image{
  background: url('https://imandiao.oss-cn-beijing.aliyuncs.com/website/images/index-background.png') no-repeat center;
  background-size: cover;
  object-fit: cover;
}
@media (max-width: 768px) {
  .work-life{
    height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .home-bg{
    height: 480px;
  }
}

.work-life-boxing{
  text-align: center;
}

.work-life{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.english-text{
  font-size: 5rem;
  margin: 30px 0 40px 0;
  width: 100%;
  color: #fff;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .english-text{
    font-size: 2rem;
  }
}

.life-text{
  width: 100%;
  position: absolute;
  color: #606060;
  font-size: 2rem;
  letter-spacing: 16px;
  top: 20%;
}

@media (max-width: 768px) {
  .life-text{
    position: absolute;
    letter-spacing: 8px;
    font-size: 1rem;
    top: 28%;
  }
}

.info-text {
  margin: 0 40px 0 40px;
  font-weight: 300;
  font-size: 1.4rem;
  color: #606060;
  line-height: 38px;
}

@media (max-width: 768px) {
  .info-text{
    margin: 0 20px 0 20px;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}
.small-title{
  font-size: 1.2rem;
  margin: 10px 0 20px 0;
  letter-spacing: 0.375rem;
  color: #606060;
}
@media (max-width: 768px) {
  .small-title{
    margin: 10px 0 20px 0;
    font-size: 0.75rem;
    letter-spacing: 2px;
  }
}

.small-title-img{
  width: 90%;
}

.business-border{
  display: flex;
  justify-content: center;
  align-items: center;
}
.images-div{
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
}
.images {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 232px;
    height: 232px;
    margin-right: 10px;
    @media (max-width: 768px) {
      margin-right: 5px;
      height: 60px;
      width: 60px;
    }
  }
}
.case-box{
  position: relative;
  height: 1089px;
  background-color: #FFFFFF;
}

@media (max-width: 768px) {
  .case-box {
    height: 360px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .case-box {
    height: 700px;
  }
}

.case-box-start{
  height: 583px;
  background-color: rgba(230, 0, 18, 1);
  padding-top: 118px;
}

@media (max-width: 768px) {
  .case-box-start{
    height: 210px;
    padding-top: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .case-box-start{
    height: 380px;
    padding-top: 50px;
  }
}

.case-title{
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-images{
  position: absolute;
  background-color: transparent;
  width: 100%;
  top: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .show-images{
    top: 20%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .show-images{
    top: 20%;
  }
}

.enterprise-box{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #FFFFFF;
  padding-bottom: 60px;
}

@media (max-width: 768px) {
  .enterprise-box{
    padding-bottom: 30px;
  }
}

.trademark-div{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  width: 90%;
}
@media (max-width: 768px) {
  .trademark-div{
    margin-top: 30px;
  }
}

.trademark-div img{
  width: 70%;
  height: 400px;
}

@media (max-width: 768px) {
  .trademark-div img{
    width: 100%;
    height: auto;
  }
}
</style>
