<template>
  <div class="data-list">
    <div v-for="item in dataList" :key="item.id" >
      <div class="data-list-item">
        <div class="data-list-item-title">
          <div class="item-text-div">{{item.name}}</div>
          <div class="item-info">{{item.info}}</div>
        </div>
        <div class="data-list-item-content">
          <div class="data-list-item-content-text" v-for="(text,index) in item.children" :key="index">
            {{text}}
          </div>
          <div class="rightTriangle"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ShowData',
  props: ['dataList']
}
</script>

<style scoped>
.data-list{
  padding: 50px 0 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .data-list{
    padding: 13px 6px 22px 0;
  }
}

.data-list-item{
  margin: 0 26px 0 0;
}

@media (max-width: 768px) {
  .data-list-item{
    margin: 23px 6px 22px 0;
  }
}
.data-list-item-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #E60012;
  width: 220px;
  height: 220px;
  border-radius: 35px 0 0 0;
}

@media (max-width: 768px) {
  .data-list-item-title{
    width: 110px;
    height: 110px;
    border-radius: 17px 0 0 0;
  }
}

.item-text-div{
  text-align: center;
  color: #FFFFFF;
  font-weight: 300;
  font-size: 2.125rem;
  width: 80px;
  height: 90px;
  line-height: 48px;
}

@media (max-width: 768px) {
  .item-text-div{
    font-size: 1.125rem;
    width: 40px;
    height: 55px;
    line-height: 1.8rem;
  }
}

.item-info{
  height: 16px;
  line-height: 23px;
  margin-top: 28px;
  font-weight: 300;
  font-size: 1.3rem;
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .item-info{
    font-size: 0.625rem;
    height: 8px;
    line-height: 12px;
  }
}

.data-list-item-content{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 376px;
  background: #F1F2F5;
}
@media (max-width: 768px) {
  .data-list-item-content{
    width: 110px;
    height: 188px;
  }
}

.data-list-item-content-text{
  font-weight: 300;
  font-size: 1.25rem;
  color: #000000;
}

@media (max-width: 768px) {
  .data-list-item-content-text{
    font-size: 0.625rem;
  }
}

.rightTriangle {
  position: absolute;
  border-top: 13px solid transparent;
  border-right: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-left: 13px solid #E60012;
  top: 95%;
  right: 90%;
}
@media (max-width: 768px) {
  .rightTriangle {
    top: 90%;
    right: 85%;
  }
}
</style>
